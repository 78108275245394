<script>
import Chart from "./Chart";

export default {
	name: "SeasonChart",
	extends: Chart,
	props: {
		season: {
			type: String,
			required: true
		},
		year: {
			type: Number,
			required: true
		}
	},
	computed: {
		chart() {
			return this.$store.getters.chart(`${this.year}-${this.season}`);
		},
		title() {
			return `${this.season} ${this.year} Anime Season`;
		}
	},
	beforeMount() {
		this.fetchChart("Season", this.year, this.season);
		this.$store.commit("setCurrentChart", `${this.year}-${this.season}`);
	},
	beforeRouteUpdate(to, from, next) {
		this.fetchChart("Season", to.params.year, to.params.season);
		this.$store.commit("setCurrentChart", `${to.params.year}-${to.params.season}`);
		next();
	},
	methods: {
		async fetchSeason(year, season) {
			await this.$store.dispatch("fetchSeason", { year, season });
		}
	}
};
</script>
